import React from 'react';

const SpaceRebelLogo = ({ height = '71', width = '71' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 71 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby="srLogoTitleID"
  >
    <title id="srLogoTitleID">Space rebel helmet</title>
    <path
      d="M65.0936 30.3894C65.0936 45.6693 51.9942 58.2788 35.5532 58.2788C19.1121 58.2788 6.0127 45.6693 6.0127 30.3894C6.0127 15.1095 19.1121 2.5 35.5532 2.5C51.9942 2.5 65.0936 15.1095 65.0936 30.3894Z"
      stroke="white"
      strokeWidth="5"
    />
    <path
      d="M66.8065 30.3894C66.8065 36.1 63.4569 41.3775 57.8225 45.2698C52.1922 49.1593 44.3595 51.597 35.6596 51.597C26.9597 51.597 19.127 49.1593 13.4967 45.2698C7.86232 41.3775 4.5127 36.1 4.5127 30.3894C4.5127 24.6788 7.86232 19.4013 13.4967 15.509C19.127 11.6195 26.9597 9.18176 35.6596 9.18176C44.3595 9.18176 52.1922 11.6195 57.8225 15.509C63.4569 19.4013 66.8065 24.6788 66.8065 30.3894Z"
      stroke="white"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6543 25.4337V30.3889H8.6543V25.149V24.8648L8.89852 24.7194C19.5422 18.3826 28.4079 15.5764 36.8713 15.7762C45.3337 15.9759 53.3241 19.1794 62.2164 24.7248L62.4519 24.8716V25.149V30.3889H61.4519V25.4269C52.731 20.0198 44.9853 16.9679 36.8477 16.7759C28.7015 16.5837 20.0938 19.2561 9.6543 25.4337ZM61.4521 35.3073V30.3891H62.4521V35.5929V35.8782L62.2066 36.0233C51.563 42.3167 42.6977 45.1034 34.2352 44.905C25.7737 44.7067 17.7838 41.5255 8.89132 36.018L8.65458 35.8714V35.5929V30.3891H9.65458V35.3141C18.3748 40.6836 26.1206 43.7146 34.2586 43.9053C42.4055 44.0962 51.0132 41.4421 61.4521 35.3073Z"
      fill="white"
    />
    <path
      d="M0 23.4738L5.10944 21.6232V39.4477L0 37.1101V23.4738Z"
      fill="white"
    />
    <path
      d="M71 23.4738L65.8906 21.6232V39.4477L71 37.1101V23.4738Z"
      fill="white"
    />
    <path
      d="M11.6025 58.9281L14.583 52.9866L19.3731 55.3243L24.3761 58.1489L30.2307 59.3178L38.1078 59.9022L42.3656 59.3178L46.5171 58.1489L53.8619 54.5451L56.5231 52.4022L60.7809 58.9281C59.0189 61.4998 57.5254 62.9503 53.8619 65.5515C49.7756 67.799 47.5323 68.6248 43.643 69.2527C36.8178 70.3071 32.9613 70.4689 25.9728 69.2527L17.5636 65.5515C15.1127 63.3819 13.8441 61.809 11.6025 58.9281Z"
      fill="white"
      stroke="white"
    />
  </svg>
);

export default SpaceRebelLogo;
